



























import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseCard from '@/components/partials/BaseCard.vue';
import LinkWithArrow from '@/components/partials/LinkWithArrow.vue';

@Component({
  components: { BaseCard, LinkWithArrow },
})
export default class AppCard extends Vue {
  @Prop({ required: true })
  private readonly imageSrc!: string;

  @Prop({ required: true })
  private readonly heading!: string;

  @Prop({ required: true })
  private readonly osName!: string;

  @Prop({ default: '' })
  private readonly operatingSystemName!: string;

  @Prop({ default: '' })
  private readonly version!: string;

  @Prop({ default: '' })
  private readonly size!: string;

  @Prop({ default: '' })
  private readonly languages!: string;

  @Prop({ required: true })
  private readonly linkText!: string;

  @Prop({ required: true })
  private readonly linkHref!: string;

  private externalLinkToApp() {
    let url: string = this.linkHref;

    if (!url.startsWith('http')) {
      url = `https://${url}`;
    }

    window.open(url, '_blank');
  }
}
