




























































































































































import { Component, Vue } from 'vue-property-decorator';
import MarkdownEditor from '@/components/partials/MarkdownEditor.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import { CompanyInformation } from '@/apis/userapi';
import {
  ProjectRestDto,
  ProjectOverviewRestDto,
  AppTypeRestDtoAppTypeEnum,
  ProjectUploadRestDto,
} from '@/apis/projectapi';
import Header from '@/assets/headers';
import Role from '@/assets/roles';
import Services from '@/assets/services/Services';
import AppPermission from '@/assets/types/permissions';
import AppCard from '@/components/AppCard.vue';
import ImageGallery from '@/components/ImageGallery.vue';
import BackLinkWithArrow from '@/components/partials/BackLinkWithArrow.vue';
import IconInCircle from '@/components/partials/IconInCircle.vue';
import LinkWithArrow from '@/components/partials/LinkWithArrow.vue';
import RoundedButtonOutlined from '@/components/partials/RoundedButtonOutlined.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import ProjectGrid from '@/components/projects/ProjectGrid.vue';
import ButtonType from '@/assets/buttonTypes';
import LeafletMap from '@/components/partials/LeafletMap.vue';
import Page from '@/components/partials/Page.vue';

@Component({
  components: {
    MarkdownEditor,
    LoadingAnimation,
    ImageGallery,
    ProjectGrid,
    AppCard,
    IconInCircle,
    BackLinkWithArrow,
    RoundedButtonOutlined,
    TextHeader,
    LinkWithArrow,
    LeafletMap,
    Page,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class ProjectDetails extends Vue {
  private loading: boolean = true;
  private showUnexpectedErrorMessage: boolean = false;
  private likeAppLoading: boolean = false;
  private isLoggedIn = false;
  private role: Role = Role.NOROLE;
  private userId: string = '';
  private isAppLikedByUser: boolean = false;
  private ButtonType = ButtonType;
  private project: ProjectRestDto = {};
  private showDescription: boolean = true;

  private Header = Header;
  private AppPermission = AppPermission;
  private logoSrc = '';
  private galleryImages: ProjectUploadRestDto[] | undefined = [];

  private permissions: AppPermission[] = [];

  private otherProjects: ProjectOverviewRestDto[] = [];
  private companyInformation: CompanyInformation = {};

  private mapCenter: number[] = [51.50617819737357, 11.031659487698656];
  private mapZoom: number = 6;

  private get appVersions() {

    if (!this.project.types) {
      return [];
    }

    const apps: { osName: string; version: string | undefined; weblink: string | undefined; languages: string | undefined; size: number | undefined, linkText: string}[] = [];

    for (let i = 0; i < this.project.types.length; i += 1) {
      const app = this.project.types[i];
      let version: string | undefined;
      let osName: string;
      let linkText: string = '';

      if (app.appType === AppTypeRestDtoAppTypeEnum.Android) {
        version = app.minAndroidVersion?.name;
        osName = 'Android';
        linkText = 'Zu Google Play wechseln';
      } else if (app.appType === AppTypeRestDtoAppTypeEnum.Ios) {
        version = app.minIOSVersion?.name;
        osName = 'iOS';
        linkText = 'Zum App Store wechseln';
      } else {
        version = '';
        osName = 'Web';
        linkText = 'Zur Web App wechseln';
      }

      apps.push({
        osName: osName,
        version: version,
        weblink: app.weblink,
        languages: app.languages?.join(','),
        size: app.size,
        linkText: linkText,
      });
    }

    return apps;
  }

  private async mounted() {
    try {
      this.project = await this.loadProject(this.$route.params.slug);
      this.centerMap(this.project);
      const appLogoUpload: ProjectUploadRestDto | undefined = this.project.uploads?.find((upload) => upload.isAppLogo);
      this.galleryImages = this.project.uploads?.filter((upload) => !upload.isAppLogo);
      if (appLogoUpload) {
        this.logoSrc = `${process.env.VUE_APP_PROJECT_SERVICE_IMAGE_URL}${appLogoUpload.id}`;
      }

      this.parsePermissions(this.project);

      const companyInformationResponse = await Services.users.getUserCompanyInformationByUserId(this.project.developerId as string);
      this.companyInformation = companyInformationResponse.data;

      await this.loadOtherProjectsOfDeveloper();

      try {
        this.isLoggedIn = this.$store.state.isLoggedIn;
        this.role = this.mapRoleStringToEnumValue(this.$store.state.role);

        if (this.isLoggedIn && this.role === Role.USER) {
          this.userId = this.$store.state.userId;
          const appLikeResponse = await Services.projects.isProjectLikedByUser(this.project.uuid as string, this.userId);
          this.isAppLikedByUser = appLikeResponse && appLikeResponse.data && (appLikeResponse.data.isLikedByUser as boolean);
        }

      } catch (e) {
        this.showUnexpectedErrorMessage = true;
      }

    } catch (e) {
      this.showUnexpectedErrorMessage = true;
    } finally {
      this.loading = false;
    }
  }

  private async centerMap(project: ProjectRestDto) {
    const mobileSize = 640;
    const centerObjects: {federalState: string, lat: number, lng: number, zoom: number}[] = [
      {
        federalState: 'Bremen', lat: 53.088998451710786, lng: 8.816649003462375, zoom: window.innerWidth > mobileSize ? 10 : 9,
      },
      {
        federalState: 'Niedersachsen', lat: 52.78885270835539, lng: 9.119028798350094, zoom: window.innerWidth > mobileSize ? 7 : 6,
      },
      {
        federalState: 'Sachsen-Anhalt', lat: 51.97071128675681, lng: 11.689522269722469, zoom: window.innerWidth > mobileSize ? 7 : 6,
      },
      {
        federalState: 'Bayern', lat: 48.93625837434266, lng: 11.469693270596608, zoom: window.innerWidth > mobileSize ? 7 : 6,
      },
      {
        federalState: 'Hamburg', lat: 53.55573374329784, lng: 10.00298613434904, zoom: window.innerWidth > mobileSize ? 10 : 9,
      },
      {
        federalState: 'Nordrhein-Westfalen', lat: 51.494423485886074, lng: 7.470536665482951, zoom: window.innerWidth > mobileSize ? 7 : 6,
      },
      {
        federalState: 'Sachsen', lat: 51.05455992559041, lng: 13.438893991750454, zoom: window.innerWidth > mobileSize ? 8 : 7,
      },
      {
        federalState: 'Berlin', lat: 52.5169782088255, lng: 13.39534466083234, zoom: window.innerWidth > mobileSize ? 10 : 9,
      },
      {
        federalState: 'Hessen', lat: 50.63835706647616, lng: 8.919758830037164, zoom: window.innerWidth > mobileSize ? 7 : 6,
      },
      {
        federalState: 'Rheinland-Pfalz', lat: 49.95055718177054, lng: 7.479520357572108, zoom: window.innerWidth > mobileSize ? 7 : 6,
      },
      {
        federalState: 'Schleswig-Holstein', lat: 54.15730784951165, lng: 9.91466559563485, zoom: window.innerWidth > mobileSize ? 8 : 7,
      },
      {
        federalState: 'Brandenburg', lat: 52.5169782088255, lng: 13.39534466083234, zoom: window.innerWidth > mobileSize ? 7 : 6,
      },
      {
        federalState: 'Mecklenburg-Vorpommern', lat: 53.806834709431435, lng: 12.470034299196882, zoom: window.innerWidth > mobileSize ? 8 : 7,
      },
      {
        federalState: 'Saarland', lat: 49.41080566765879, lng: 6.989697222943084, zoom: window.innerWidth > mobileSize ? 9 : 8,
      },
      {
        federalState: 'Thüringen', lat: 50.88538382007476, lng: 11.013797786685963, zoom: window.innerWidth > mobileSize ? 8 : 7,
      },
      {
        federalState: 'Baden-Württemberg', lat: 48.70280440125915, lng: 9.069213867187502, zoom: window.innerWidth > mobileSize ? 7 : 7,
      },
    ];
    let federalState = '';
    if (project.federalStates && project.federalStates.length === 1) {
      const f = project.federalStates[0];
      federalState = f;
    } else if (project.federalStates && project.federalStates.length === 0 && project.counties && project.counties.length === 1) {
      try {
        const response = await Services.projects.downloadSpecificCountiesGeoData(project.counties);
        federalState = (response.data as any).features[0].properties.NAME_1 as string;
      } catch (error) {
        federalState = '';
      }
    }
    if (federalState !== '') {
      const filteredCenterObjects = centerObjects.filter((o) => o.federalState === federalState);
      if (filteredCenterObjects.length === 1) {
        const centerObject = filteredCenterObjects[0];
        this.mapCenter = [centerObject.lat, centerObject.lng];
        this.mapZoom = centerObject.zoom;
      }
    }
  }

  private get companyLogoImagePath(): string {

    let path = '';

    if (this.companyInformation.companyLogo && this.companyInformation.companyLogo.id) {

      path = `${this.companyInformation.companyLogo.filename}.${this.companyInformation.companyLogo.filetype}`;

      if (this.companyInformation.companyLogo.isTemporaryUpload) {
        path = `${process.env.VUE_APP_USER_SERVICE_IMAGE_URL}temporary/${this.companyInformation.companyLogo.id}`;
      } else {
        path = `${process.env.VUE_APP_USER_SERVICE_IMAGE_URL}${this.companyInformation.companyLogo.id}`;
      }
    }

    return path;
  }

  private get galleryImagePaths(): string[] {
    if (!this.galleryImages) return [];
    return this.galleryImages.map((i) => `${process.env.VUE_APP_PROJECT_SERVICE_IMAGE_URL}${i.id}`);
  }

  private mapRoleStringToEnumValue(role: string): Role {
    switch (role) {
      case 'User':
        return Role.USER;
      case 'Developer':
        return Role.DEVELOPER;
      case 'Admin':
        return Role.ADMIN;
      default:
        return Role.NOROLE;
    }
  }

  private async loadProject(projectNameSlug: string): Promise<ProjectRestDto> {
    const response = await Services.projects.getProjectBySlug(projectNameSlug);
    return response.data;
  }

  private clickToWebsiteLink() {
    const url: string = this.project.website as string;

    // TODO
    // if (!url.startsWith('http')) {
    //   url = `https://${url}`;
    // }
    window.open(url);
  }

  private async likeApp() {
    this.likeAppLoading = true;
    await Services.projects.addProjectToUserLikeList(this.userId, this.project.uuid as string);
    this.isAppLikedByUser = true;
    this.likeAppLoading = false;
  }

  private async dislikeApp() {
    this.likeAppLoading = true;
    await Services.projects.removeProjectFromUserLikeList(this.userId, this.project.uuid as string);
    this.isAppLikedByUser = false;
    this.likeAppLoading = false;
  }

  private async loadOtherProjectsOfDeveloper() {
    const response = await Services.projects.getProjectsByDeveloperUserId(this.project.developerId as string);
    this.otherProjects = response.data.filter((project) => project.uuid !== this.project.uuid);
  }

  private parsePermissions(project: ProjectRestDto) {
    this.addPermissionIfTrue(project.usesBluetooth, AppPermission.BLUETOOTH);
    this.addPermissionIfTrue(project.usesBodyData, AppPermission.BODY_DATA);
    this.addPermissionIfTrue(project.usesCalendar, AppPermission.CALENDAR);
    this.addPermissionIfTrue(project.usesCamera, AppPermission.CAMERA);
    this.addPermissionIfTrue(project.usesContacts, AppPermission.CONTACTS);
    this.addPermissionIfTrue(project.usesFilesystem, AppPermission.FILESYSTEM);
    this.addPermissionIfTrue(project.usesGps, AppPermission.LOCATION);
    this.addPermissionIfTrue(project.usesMicrophone, AppPermission.MICROPHONE);
    this.addPermissionIfTrue(project.usesNotes, AppPermission.NOTES);
    this.addPermissionIfTrue(project.usesPhotos, AppPermission.PHOTOS);
    this.addPermissionIfTrue(project.usesVoiceRecognition, AppPermission.SPEECH_RECOGNITION);
    this.addPermissionIfTrue(project.usesSingleSignOn, AppPermission.SINGLE_SIGN_ON);
  }

  private addPermissionIfTrue(isPermissionUsed: boolean | undefined, permissionToSet: AppPermission) {
    if (isPermissionUsed) {
      this.permissions.push(permissionToSet);
    }
  }

  private get showAddToAppsButton() {
    return this.isLoggedIn && this.role === Role.USER;
  }
}
